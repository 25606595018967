@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);
@font-face {
  font-family: 'Feather';
  src: url(/static/media/Feather.818cdac2.eot);
  src: url(/static/media/Feather.818cdac2.eot#iefix) format('embedded-opentype'),
    url(/static/media/Feather.68f7d7f1.ttf) format('truetype'), url(/static/media/Feather.ac808b86.woff) format('woff'),
    url(/static/media/Feather.bbe9e090.svg#Feather) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='feather-'],
[class*=' feather-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Feather' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.feather-activity:before {
  content: '\e902';
}
.feather-airplay:before {
  content: '\e903';
}
.feather-alert-circle:before {
  content: '\e904';
}
.feather-alert-octagon:before {
  content: '\e905';
}
.feather-alert-triangle:before {
  content: '\e906';
}
.feather-align-center:before {
  content: '\e907';
}
.feather-align-justify:before {
  content: '\e908';
}
.feather-align-left:before {
  content: '\e909';
}
.feather-align-right:before {
  content: '\e90a';
}
.feather-anchor:before {
  content: '\e90b';
}
.feather-aperture:before {
  content: '\e90c';
}
.feather-archive:before {
  content: '\e90d';
}
.feather-arrow-down:before {
  content: '\e90e';
}
.feather-arrow-down-circle:before {
  content: '\e90f';
}
.feather-arrow-down-left:before {
  content: '\e910';
}
.feather-arrow-down-right:before {
  content: '\e911';
}
.feather-arrow-left:before {
  content: '\e912';
}
.feather-arrow-left-circle:before {
  content: '\e913';
}
.feather-arrow-right:before {
  content: '\e914';
}
.feather-arrow-right-circle:before {
  content: '\e915';
}
.feather-arrow-up:before {
  content: '\e916';
}
.feather-arrow-up-circle:before {
  content: '\e917';
}
.feather-arrow-up-left:before {
  content: '\e918';
}
.feather-arrow-up-right:before {
  content: '\e919';
}
.feather-at-sign:before {
  content: '\e91a';
}
.feather-award:before {
  content: '\e91b';
}
.feather-bar-chart:before {
  content: '\e91c';
}
.feather-bar-chart-2:before {
  content: '\e91d';
}
.feather-battery:before {
  content: '\e91e';
}
.feather-battery-charging:before {
  content: '\e91f';
}
.feather-bell:before {
  content: '\e920';
}
.feather-bell-off:before {
  content: '\e921';
}
.feather-bluetooth:before {
  content: '\e922';
}
.feather-bold:before {
  content: '\e923';
}
.feather-book:before {
  content: '\e924';
}
.feather-book-open:before {
  content: '\e925';
}
.feather-bookmark:before {
  content: '\e926';
}
.feather-box:before {
  content: '\e927';
}
.feather-briefcase:before {
  content: '\e928';
}
.feather-calendar:before {
  content: '\e929';
}
.feather-camera:before {
  content: '\e92a';
}
.feather-camera-off:before {
  content: '\e92b';
}
.feather-cast:before {
  content: '\e92c';
}
.feather-check:before {
  content: '\e92d';
}
.feather-check-circle:before {
  content: '\e92e';
}
.feather-check-square:before {
  content: '\e92f';
}
.feather-chevron-down:before {
  content: '\e930';
}
.feather-chevron-left:before {
  content: '\e931';
}
.feather-chevron-right:before {
  content: '\e932';
}
.feather-chevron-up:before {
  content: '\e933';
}
.feather-chevrons-down:before {
  content: '\e934';
}
.feather-chevrons-left:before {
  content: '\e935';
}
.feather-chevrons-right:before {
  content: '\e936';
}
.feather-chevrons-up:before {
  content: '\e937';
}
.feather-chrome:before {
  content: '\e938';
}
.feather-circle:before {
  content: '\e939';
}
.feather-clipboard:before {
  content: '\e93a';
}
.feather-clock:before {
  content: '\e93b';
}
.feather-cloud:before {
  content: '\e93c';
}
.feather-cloud-drizzle:before {
  content: '\e93d';
}
.feather-cloud-lightning:before {
  content: '\e93e';
}
.feather-cloud-off:before {
  content: '\e93f';
}
.feather-cloud-rain:before {
  content: '\e940';
}
.feather-cloud-snow:before {
  content: '\e941';
}
.feather-code:before {
  content: '\e942';
}
.feather-codepen:before {
  content: '\e943';
}
.feather-command:before {
  content: '\e947';
}
.feather-compass:before {
  content: '\e948';
}
.feather-copy:before {
  content: '\e949';
}
.feather-corner-down-left:before {
  content: '\e94a';
}
.feather-corner-down-right:before {
  content: '\e94b';
}
.feather-corner-left-down:before {
  content: '\e94c';
}
.feather-corner-left-up:before {
  content: '\e94d';
}
.feather-corner-right-down:before {
  content: '\e94e';
}
.feather-corner-right-up:before {
  content: '\e94f';
}
.feather-corner-up-left:before {
  content: '\e950';
}
.feather-corner-up-right:before {
  content: '\e951';
}
.feather-cpu:before {
  content: '\e952';
}
.feather-credit-card:before {
  content: '\e953';
}
.feather-crop:before {
  content: '\e954';
}
.feather-crosshair:before {
  content: '\e955';
}
.feather-database:before {
  content: '\e956';
}
.feather-delete:before {
  content: '\e957';
}
.feather-disc:before {
  content: '\e958';
}
.feather-dollar-sign:before {
  content: '\e959';
}
.feather-download:before {
  content: '\e95a';
}
.feather-download-cloud:before {
  content: '\e95b';
}
.feather-droplet:before {
  content: '\e95c';
}
.feather-edit:before {
  content: '\e95d';
}
.feather-edit-2:before {
  content: '\e95e';
}
.feather-edit-3:before {
  content: '\e95f';
}
.feather-external-link:before {
  content: '\e960';
}
.feather-eye:before {
  content: '\e961';
}
.feather-eye-off:before {
  content: '\e962';
}
.feather-facebook:before {
  content: '\e963';
}
.feather-fast-forward:before {
  content: '\e964';
}
.feather-feather:before {
  content: '\e965';
}
.feather-file:before {
  content: '\e967';
}
.feather-file-minus:before {
  content: '\e968';
}
.feather-file-plus:before {
  content: '\e969';
}
.feather-file-text:before {
  content: '\e96a';
}
.feather-film:before {
  content: '\e96b';
}
.feather-filter:before {
  content: '\e96c';
}
.feather-flag:before {
  content: '\e96d';
}
.feather-folder:before {
  content: '\e96e';
}
.feather-folder-minus:before {
  content: '\e96f';
}
.feather-folder-plus:before {
  content: '\e970';
}
.feather-gift:before {
  content: '\e973';
}
.feather-git-branch:before {
  content: '\e974';
}
.feather-git-commit:before {
  content: '\e975';
}
.feather-git-merge:before {
  content: '\e976';
}
.feather-git-pull-request:before {
  content: '\e977';
}
.feather-github:before {
  content: '\e978';
}
.feather-gitlab:before {
  content: '\e979';
}
.feather-globe:before {
  content: '\e97a';
}
.feather-grid:before {
  content: '\e97b';
}
.feather-hard-drive:before {
  content: '\e97c';
}
.feather-hash:before {
  content: '\e97d';
}
.feather-headphones:before {
  content: '\e97e';
}
.feather-heart:before {
  content: '\e97f';
}
.feather-help-circle:before {
  content: '\e980';
}
.feather-home:before {
  content: '\e982';
}
.feather-image:before {
  content: '\e983';
}
.feather-inbox:before {
  content: '\e984';
}
.feather-info:before {
  content: '\e985';
}
.feather-instagram:before {
  content: '\e986';
}
.feather-italic:before {
  content: '\e987';
}
.feather-layers:before {
  content: '\e989';
}
.feather-layout:before {
  content: '\e98a';
}
.feather-life-buoy:before {
  content: '\e98b';
}
.feather-link:before {
  content: '\e98c';
}
.feather-link-2:before {
  content: '\e98d';
}
.feather-linkedin:before {
  content: '\e98e';
}
.feather-list:before {
  content: '\e98f';
}
.feather-loader:before {
  content: '\e990';
}
.feather-lock:before {
  content: '\e991';
}
.feather-log-in:before {
  content: '\e992';
}
.feather-log-out:before {
  content: '\e993';
}
.feather-mail:before {
  content: '\e994';
}
.feather-map:before {
  content: '\e995';
}
.feather-map-pin:before {
  content: '\e996';
}
.feather-maximize:before {
  content: '\e997';
}
.feather-maximize-2:before {
  content: '\e998';
}
.feather-menu:before {
  content: '\e99a';
}
.feather-message-circle:before {
  content: '\e99b';
}
.feather-message-square:before {
  content: '\e99c';
}
.feather-mic:before {
  content: '\e99d';
}
.feather-mic-off:before {
  content: '\e99e';
}
.feather-minimize:before {
  content: '\e99f';
}
.feather-minimize-2:before {
  content: '\e9a0';
}
.feather-minus:before {
  content: '\e9a1';
}
.feather-minus-circle:before {
  content: '\e9a2';
}
.feather-minus-square:before {
  content: '\e9a3';
}
.feather-monitor:before {
  content: '\e9a4';
}
.feather-moon:before {
  content: '\e9a5';
}
.feather-more-horizontal:before {
  content: '\e9a6';
}
.feather-more-vertical:before {
  content: '\e9a7';
}
.feather-move:before {
  content: '\e9a9';
}
.feather-music:before {
  content: '\e9aa';
}
.feather-navigation:before {
  content: '\e9ab';
}
.feather-navigation-2:before {
  content: '\e9ac';
}
.feather-octagon:before {
  content: '\e9ad';
}
.feather-package:before {
  content: '\e9ae';
}
.feather-paperclip:before {
  content: '\e9af';
}
.feather-pause:before {
  content: '\e9b0';
}
.feather-pause-circle:before {
  content: '\e9b1';
}
.feather-percent:before {
  content: '\e9b3';
}
.feather-phone:before {
  content: '\e9b4';
}
.feather-phone-call:before {
  content: '\e9b5';
}
.feather-phone-forwarded:before {
  content: '\e9b6';
}
.feather-phone-incoming:before {
  content: '\e9b7';
}
.feather-phone-missed:before {
  content: '\e9b8';
}
.feather-phone-off:before {
  content: '\e9b9';
}
.feather-phone-outgoing:before {
  content: '\e9ba';
}
.feather-pie-chart:before {
  content: '\e9bb';
}
.feather-play:before {
  content: '\e9bc';
}
.feather-play-circle:before {
  content: '\e9bd';
}
.feather-plus:before {
  content: '\e9be';
}
.feather-plus-circle:before {
  content: '\e9bf';
}
.feather-plus-square:before {
  content: '\e9c0';
}
.feather-pocket:before {
  content: '\e9c1';
}
.feather-power:before {
  content: '\e9c2';
}
.feather-printer:before {
  content: '\e9c3';
}
.feather-radio:before {
  content: '\e9c4';
}
.feather-refresh-ccw:before {
  content: '\e9c5';
}
.feather-refresh-cw:before {
  content: '\e9c6';
}
.feather-repeat:before {
  content: '\e9c7';
}
.feather-rewind:before {
  content: '\e9c8';
}
.feather-rotate-ccw:before {
  content: '\e9c9';
}
.feather-rotate-cw:before {
  content: '\e9ca';
}
.feather-rss:before {
  content: '\e9cb';
}
.feather-save:before {
  content: '\e9cc';
}
.feather-scissors:before {
  content: '\e9cd';
}
.feather-search:before {
  content: '\e9ce';
}
.feather-send:before {
  content: '\e9cf';
}
.feather-server:before {
  content: '\e9d0';
}
.feather-settings:before {
  content: '\e9d1';
}
.feather-share:before {
  content: '\e9d2';
}
.feather-share-2:before {
  content: '\e9d3';
}
.feather-shield:before {
  content: '\e9d4';
}
.feather-shield-off:before {
  content: '\e9d5';
}
.feather-shopping-bag:before {
  content: '\e9d6';
}
.feather-shopping-cart:before {
  content: '\e9d7';
}
.feather-shuffle:before {
  content: '\e9d8';
}
.feather-sidebar:before {
  content: '\e9d9';
}
.feather-skip-back:before {
  content: '\e9da';
}
.feather-skip-forward:before {
  content: '\e9db';
}
.feather-slack:before {
  content: '\e9dc';
}
.feather-slash:before {
  content: '\e9dd';
}
.feather-sliders:before {
  content: '\e9de';
}
.feather-smartphone:before {
  content: '\e9df';
}
.feather-speaker:before {
  content: '\e9e1';
}
.feather-square:before {
  content: '\e9e2';
}
.feather-star:before {
  content: '\e9e3';
}
.feather-stop-circle:before {
  content: '\e9e4';
}
.feather-sun:before {
  content: '\e9e5';
}
.feather-sunrise:before {
  content: '\e9e6';
}
.feather-sunset:before {
  content: '\e9e7';
}
.feather-tablet:before {
  content: '\e9e8';
}
.feather-tag:before {
  content: '\e9e9';
}
.feather-target:before {
  content: '\e9ea';
}
.feather-terminal:before {
  content: '\e9eb';
}
.feather-thermometer:before {
  content: '\e9ec';
}
.feather-thumbs-down:before {
  content: '\e9ed';
}
.feather-thumbs-up:before {
  content: '\e9ee';
}
.feather-toggle-left:before {
  content: '\e9ef';
}
.feather-toggle-right:before {
  content: '\e9f0';
}
.feather-trash:before {
  content: '\e9f2';
}
.feather-trash-2:before {
  content: '\e9f3';
}
.feather-trending-down:before {
  content: '\e9f5';
}
.feather-trending-up:before {
  content: '\e9f6';
}
.feather-triangle:before {
  content: '\e9f7';
}
.feather-truck:before {
  content: '\e9f8';
}
.feather-tv:before {
  content: '\e9f9';
}
.feather-twitter:before {
  content: '\e9fb';
}
.feather-type:before {
  content: '\e9fc';
}
.feather-umbrella:before {
  content: '\e9fd';
}
.feather-underline:before {
  content: '\e9fe';
}
.feather-unlock:before {
  content: '\e9ff';
}
.feather-upload:before {
  content: '\ea00';
}
.feather-upload-cloud:before {
  content: '\ea01';
}
.feather-user:before {
  content: '\ea02';
}
.feather-user-check:before {
  content: '\ea03';
}
.feather-user-minus:before {
  content: '\ea04';
}
.feather-user-plus:before {
  content: '\ea05';
}
.feather-user-x:before {
  content: '\ea06';
}
.feather-users:before {
  content: '\ea07';
}
.feather-video:before {
  content: '\ea08';
}
.feather-video-off:before {
  content: '\ea09';
}
.feather-voicemail:before {
  content: '\ea0a';
}
.feather-volume:before {
  content: '\ea0b';
}
.feather-volume-1:before {
  content: '\ea0c';
}
.feather-volume-2:before {
  content: '\ea0d';
}
.feather-volume-x:before {
  content: '\ea0e';
}
.feather-watch:before {
  content: '\ea0f';
}
.feather-wifi:before {
  content: '\ea10';
}
.feather-wifi-off:before {
  content: '\ea11';
}
.feather-wind:before {
  content: '\ea12';
}
.feather-x:before {
  content: '\ea13';
}
.feather-x-circle:before {
  content: '\ea14';
}
.feather-x-square:before {
  content: '\ea16';
}
.feather-youtube:before {
  content: '\ea17';
}
.feather-zap:before {
  content: '\ea18';
}
.feather-zap-off:before {
  content: '\ea19';
}
.feather-zoom-in:before {
  content: '\ea1a';
}
.feather-zoom-out:before {
  content: '\ea1b';
}
.feather-codesandbox:before {
  content: '\e944';
}
.feather-coffee:before {
  content: '\e945';
}
.feather-columns:before {
  content: '\e946';
}
.feather-figma:before {
  content: '\e966';
}
.feather-framer:before {
  content: '\e971';
}
.feather-frown:before {
  content: '\e972';
}
.feather-hexagon:before {
  content: '\e981';
}
.feather-key:before {
  content: '\e988';
}
.feather-meh:before {
  content: '\e999';
}
.feather-mouse-pointer:before {
  content: '\e9a8';
}
.feather-pen-tool:before {
  content: '\e9b2';
}
.feather-smile:before {
  content: '\e9e0';
}
.feather-tool:before {
  content: '\e9f1';
}
.feather-trello:before {
  content: '\e9f4';
}
.feather-twitch:before {
  content: '\e9fa';
}
.feather-x-octagon:before {
  content: '\ea15';
}
.feather-next-calendar:before {
  content: '\e900';
}

@font-face {
  font-family: 'salescamp';
  src: url(/static/media/salescamp.f6c821b4.eot);
  src: url(/static/media/salescamp.f6c821b4.eot#iefix) format('embedded-opentype'),
    url(/static/media/salescamp.0cdd948d.ttf) format('truetype'), url(/static/media/salescamp.257456e3.woff) format('woff'),
    url(/static/media/salescamp.a2771da9.svg#salescamp) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'salescamp' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-account:before {
  content: '\e900';
}
.icon-add-account:before {
  content: '\e901';
}
.icon-add-call:before {
  content: '\e91d';
}
.icon-add-channel:before {
  content: '\e902';
}
.icon-add-notes:before {
  content: '\e903';
}
.icon-add-number:before {
  content: '\e904';
}
.icon-add-plus:before {
  content: '\e905';
}
.icon-assign:before {
  content: '\e906';
}
.icon-attachment:before {
  content: '\e907';
}
.icon-attachment-link:before {
  content: '\e975';
}
.icon-audio-disabled:before {
  content: '\e97d';
}
.icon-audio-nondisabled:before {
  content: '\e97e';
}
.icon-audio-pause:before {
  content: '\e976';
}
.icon-audio-play:before {
  content: '\e977';
}
.icon-backspace:before {
  content: '\e908';
}
.icon-big-down-arrow:before {
  content: '\e909';
}
.icon-big-left-arrow:before {
  content: '\e90a';
}
.icon-big-right-arrow:before {
  content: '\e90b';
}
.icon-big-up-arrow:before {
  content: '\e90c';
}
.icon-bold:before {
  content: '\e90d';
}
.icon-bookmark:before {
  content: '\e90e';
}
.icon-browser:before {
  content: '\e97f';
}
.icon-bullet-list:before {
  content: '\e90f';
}
.icon-buy-number:before {
  content: '\e910';
}
.icon-calendar:before {
  content: '\e911';
}
.icon-calendar-view:before {
  content: '\e980';
}
.icon-call:before {
  content: '\e912';
}
.icon-call-no-answer:before {
  content: '\e981';
}
.icon-cash-protection:before {
  content: '\e982';
}
.icon-cellphone:before {
  content: '\e983';
}
.icon-check:before {
  content: '\e913';
}
.icon-check-square:before {
  content: '\e984';
}
.icon-checked-round:before {
  content: '\e914';
}
.icon-checkmark:before {
  content: '\e985';
}
.icon-clicked:before {
  content: '\e915';
}
.icon-close:before {
  content: '\e917';
}
.icon-close-round:before {
  content: '\e916';
}
.icon-code:before {
  content: '\e918';
}
.icon-column-chooser:before {
  content: '\e919';
}
.icon-column-edit:before {
  content: '\e986';
}
.icon-companies:before {
  content: '\e91a';
}
.icon-contact:before {
  content: '\e91b';
}
.icon-copy:before {
  content: '\e91c';
}
.icon-create-meeting:before {
  content: '\e987';
}
.icon-customize:before {
  content: '\e91e';
}
.icon-dashboard:before {
  content: '\e91f';
}
.icon-deals:before {
  content: '\e920';
}
.icon-delete:before {
  content: '\e921';
}
.icon-details:before {
  content: '\e922';
}
.icon-document:before {
  content: '\e923';
}
.icon-document-text:before {
  content: '\e988';
}
.icon-dots-vertical:before {
  content: '\e924';
}
.icon-down-arrow:before {
  content: '\e925';
}
.icon-download:before {
  content: '\e926';
}
.icon-draft:before {
  content: '\e927';
}
.icon-drag-and-drop:before {
  content: '\e989';
}
.icon-drop-down-menu:before {
  content: '\e98a';
}
.icon-duplicate:before {
  content: '\e98b';
}
.icon-earth:before {
  content: '\e98c';
}
.icon-edit:before {
  content: '\e929';
}
.icon-edit-pen:before {
  content: '\e928';
}
.icon-edit-summray:before {
  content: '\e98d';
}
.icon-email:before {
  content: '\e92b';
}
.icon-email-open:before {
  content: '\e92a';
}
.icon-email-symbol:before {
  content: '\e98e';
}
.icon-erase:before {
  content: '\e92c';
}
.icon-essential:before {
  content: '\e92d';
}
.icon-export:before {
  content: '\e92e';
}
.icon-feedback:before {
  content: '\e92f';
}
.icon-fill-notifications:before {
  content: '\e97a';
}
.icon-fill-star:before {
  content: '\e97b';
}
.icon-filter-sort:before {
  content: '\e930';
}
.icon-flag:before {
  content: '\e931';
}
.icon-follow-up:before {
  content: '\e932';
}
.icon-forward:before {
  content: '\e933';
}
.icon-goal:before {
  content: '\e934';
}
.icon-hide:before {
  content: '\e935';
}
.icon-idea:before {
  content: '\e936';
}
.icon-import:before {
  content: '\e938';
}
.icon-import-data:before {
  content: '\e937';
}
.icon-inbox:before {
  content: '\e939';
}
.icon-infographic:before {
  content: '\e93a';
}
.icon-information:before {
  content: '\e93b';
}
.icon-ingoing:before {
  content: '\e978';
}
.icon-input-field:before {
  content: '\e9a1';
}
.icon-invoice:before {
  content: '\e93c';
}
.icon-italic:before {
  content: '\e93d';
}
.icon-join:before {
  content: '\e93e';
}
.icon-keyboard:before {
  content: '\e98f';
}
.icon-keyboard-number:before {
  content: '\e990';
}
.icon-layout:before {
  content: '\e93f';
}
.icon-lead:before {
  content: '\e940';
}
.icon-left-arrow:before {
  content: '\e941';
}
.icon-link:before {
  content: '\e942';
}
.icon-list:before {
  content: '\e943';
}
.icon-list-tasks-checkmark:before {
  content: '\e991';
}
.icon-location:before {
  content: '\e944';
}
.icon-lunch:before {
  content: '\e992';
}
.icon-mail-opened:before {
  content: '\e945';
}
.icon-massage:before {
  content: '\e946';
}
.icon-max-mius:before {
  content: '\e947';
}
.icon-menu:before {
  content: '\e948';
}
.icon-merge:before {
  content: '\e993';
}
.icon-messenger:before {
  content: '\e949';
}
.icon-microphone-mic:before {
  content: '\e994';
}
.icon-microphone-mute:before {
  content: '\e995';
}
.icon-minus:before {
  content: '\e94a';
}
.icon-missed-call:before {
  content: '\e996';
}
.icon-more:before {
  content: '\e94b';
}
.icon-next:before {
  content: '\e94d';
}
.icon-next-week:before {
  content: '\e94c';
}
.icon-note:before {
  content: '\e94e';
}
.icon-notifications:before {
  content: '\e97c';
}
.icon-numbers:before {
  content: '\e94f';
}
.icon-other:before {
  content: '\e950';
}
.icon-outgoing:before {
  content: '\e979';
}
.icon-phone:before {
  content: '\e951';
}
.icon-pluse:before {
  content: '\e952';
}
.icon-porting:before {
  content: '\e953';
}
.icon-printer:before {
  content: '\e954';
}
.icon-quote:before {
  content: '\e955';
}
.icon-read-checkmark:before {
  content: '\e997';
}
.icon-refresh:before {
  content: '\e956';
}
.icon-repeat:before {
  content: '\e957';
}
.icon-reply:before {
  content: '\e958';
}
.icon-resource:before {
  content: '\e959';
}
.icon-rich-text:before {
  content: '\e95a';
}
.icon-right-arrow:before {
  content: '\e95b';
}
.icon-save:before {
  content: '\e95c';
}
.icon-search:before {
  content: '\e95d';
}
.icon-share:before {
  content: '\e95e';
}
.icon-show:before {
  content: '\e95f';
}
.icon-social-facebook:before {
  content: '\e998';
}
.icon-social-google:before {
  content: '\e999';
}
.icon-social-linkedin:before {
  content: '\e99a';
}
.icon-social-skype:before {
  content: '\e99b';
}
.icon-social-twitter:before {
  content: '\e99c';
}
.icon-star:before {
  content: '\e960';
}
.icon-stop:before {
  content: '\e961';
}
.icon-strike:before {
  content: '\e962';
}
.icon-summary:before {
  content: '\e99d';
}
.icon-t-delete:before {
  content: '\e963';
}
.icon-t-fomat:before {
  content: '\e964';
}
.icon-t-square:before {
  content: '\e99e';
}
.icon-tag:before {
  content: '\e965';
}
.icon-task:before {
  content: '\e966';
}
.icon-team:before {
  content: '\e967';
}
.icon-thumbs-up:before {
  content: '\e968';
}
.icon-timer:before {
  content: '\e969';
}
.icon-today:before {
  content: '\e96a';
}
.icon-toggle:before {
  content: '\e96b';
}
.icon-tomorrow:before {
  content: '\e96c';
}
.icon-tools:before {
  content: '\e96d';
}
.icon-two-way:before {
  content: '\e96e';
}
.icon-underline:before {
  content: '\e96f';
}
.icon-up-arrow:before {
  content: '\e970';
}
.icon-user:before {
  content: '\e971';
}
.icon-user-card:before {
  content: '\e99f';
}
.icon-video:before {
  content: '\e972';
}
.icon-wallet:before {
  content: '\e9a2';
}
.icon-warning:before {
  content: '\e9a0';
}
.icon-webcam:before {
  content: '\e973';
}
.icon-whatsapp:before {
  content: '\e974';
}

/* icon font */
/* @font-face {
  font-family: 'icomoon';
  src: url('../src/assets/font/icomoon.eot?q4ly9y');
  src: url('../src/assets/font/icomoon.eot?q4ly9y#iefix') format('embedded-opentype'),
    url('../src/assets/font/icomoon.ttf?q4ly9y') format('truetype'),
    url('../src/assets/font/icomoon.woff?q4ly9y') format('woff'),
    url('../src/assets/font/icomoon.svg?q4ly9y#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
} */

@font-face {
  font-family: 'salescamp';
  src: url(/static/media/salescamp.f6c821b4.eot);
  src: url(/static/media/salescamp.f6c821b4.eot#iefix) format('embedded-opentype'),
    url(/static/media/salescamp.0cdd948d.ttf) format('truetype'),
    url(/static/media/salescamp.257456e3.woff) format('woff'),
    url(/static/media/salescamp.a2771da9.svg#salescamp) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

*,
::after,
::before {
  box-sizing: border-box;
}
:root {
  --primary-font-stack: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --primary-color: #2e69ce;
  --white-color: #ffffff;
  --gray-color: #868995;
  --light-gray: #bebec1;
  --green-color: #20c997;
  --dark-color: #333333;
  --primary-color-active: #2656a7;
  --primary-color-secondery-active: #cbcbcf;
  --regent-gray-color: #808a9d;
  --main-bg: #f9f9fa;
  --star-bg: #f8d648;
  --yellow-color: #ffc048;
  --deal-column-bg: #f9fafa;
  --danger-Base-bg: #ff322e;
  --danger-Base-active: #d5201c;
  --orange-color: #f08c00;
  --orange-lite-color: #ff6b6b;
  --medium-purple-color: #9775fa;
  --Platinum-color: #e4e6e8;
  --gainsboro-color: #dcdcdc;
  --spanish-gray: #999;
  --before-after-color: rgba(228, 230, 232, 0);
  --mobile-box-shadow: rgba(10, 10, 46, 0.1);
  --pagination-box-shadow: rgba(0, 0, 0, 0.05);
  --pagination-box-shadow-hover: rgba(0, 0, 0, 0.12);
  --maximum-yellow-red: #ffc168;
  --yellow-lite-color: rgba(242, 201, 76, 0.25);
  --independence-color: #4e5971;
  --mango-color: #fbbc04;
  --Cerulean-Crayola: #04a8d5;
  --yellow-green: #92cf48;
  --green-lite: #7eeb6e;
  --red-lite: #dc3732;
  --tomato: #ff6347;
  --Gunmetal: #2c333f;
  --sheen-green: #7ed321;
  --bright-navy-blue: #197dd2;
  --bright-navy-box: rgb(25 125 210 / 50%);
  --roman-silver-color: #81878e;
  --lables-border-color: rgba(189, 189, 189, 0.6);
  --bright-navy-blue: #197dd2;
  --bright-navy-box: rgb(25 125 210 / 50%);
  --cultured-color: #f7f7f7;
  --roman-silver-color: #81878e;
  --lables-border-color: rgba(189, 189, 189, 0.6);
  --Gray-color: #bdbdbd;
  --Red-salsa-color: #eb5757;
  --bright-navy-blue: #197dd2;
  --bright-navy-box: rgb(25 125 210 / 50%);
  --gainsBoro-color: #dbdbdb;
  --roman-silver-color: #81878e;
  --lables-border-color: rgba(189, 189, 189, 0.6);
  --Gray-color: #bdbdbd;
  --Red-salsa-color: #eb5757;
  --disable-color: #b4b5ba;
  --error-bg-color: #feeeee;
  --sonic-silver: #727378;
  --cornsilk-color: #fff6dc;
  --charcoal-color: #585a61;
  --icon-fill: #545f74;
  --Shuttle-Gray: #666a77;
  --invoice-bg: #ff6c5f;
  --calendar-bg: #3b939b;
  --user-bg: #8185e0;
  --email-bg: #1cc7d0;
  --goal-bg: #2dde98;
  --draft-bg: #ff4f81;
  --phone-bg: #f476cb;
  --raven-color: #737987;
  --focus-color: #afcdff;
  --white-smoke: #f8f8f8;

  /* Border Color */
  --gray-border-color: #e4e4e5;
  --danger-Base-border: rgba(0, 0, 0, 0.2);
  --yellow-lite-border: #f2c94c;
  --alice-blue-border: #e3e8ee;
  --charcoal-border: #464950;
  --dark-blue-border: #1e2531;

  /* Icon Color */
  --gray-icon-color: #7c7c7f;
  --icon-blue: #40c4ff;
  --gray-more-icon: #98989c;
  --sea-green-icon: #20c997;
  --mantis-icon: #6fbe6c;
  --callreject-icon: #ff322e;
  --check-icon: #748ffc;
  --message-icon: #ffd600;
  --callminus-icon: rgba(255, 255, 255, 0.7);
  --number-color: rgba(255, 255, 255, 0.6);
  --icon-green: #2dde98;
  --icon-united-nations-blue: #4f96f2;
  --icon-mango: #fbbb00;
  --icon-paradise-pink: #eb466e;
  --kelly-green: #3cbf27;
  --jonquil: #edc626;
  --icon-suggestion: #ed9190;
  --titlebar-bg: #323844;
  --titlebar-tag: #bebec1;
  --unread-color: #fff3e1;
  --organize-navbar-bg: #101318;
  --shade-gray: #e2e2e2;

  /* Button Color */
  --gray-disable-color: #bebec1;

  /* nav color */
  --nav-dark-color: #202632;
  --nav-dark-color-hover: #2e3341;
  --nav-dark-color-focus: #494f5e;

  /*box-shadow*/
  --search-modal-shadow: 0px 5px 25px rgba(0, 0, 0, 0.4), 0px 15px 60px rgba(0, 0, 0, 0.4);
  --modal-box-shadow: rgba(0, 0, 0, 0.15);
  --box-shadow-two: 0px 1px 3px rgba(10, 10, 46, 0.1), 0px 3px 14px rgba(10, 10, 46, 0.04),
    0px 8px 32px rgba(10, 10, 46, 0.08), 0px 30px 84px rgba(10, 10, 46, 0.1);

  /*popup hover*/
  --popup-color-hover: #f2f2f2;
  --text-input-small: rgba(58, 62, 66, 0.15);
  --box-shadow: #f0f0f0;
  --box-shadow-secondary: rgba(37, 37, 38, 0.08);
  --modal-shadow: rgba(0, 0, 0, 0.15);
  --silver-sand: #c5c8cd;
  --menu-modal: rgba(0, 0, 0, 0.1);
  --inbox-hover-color: rgba(0, 0, 0, 0.08);
  --inbox-icon-color: rgba(0, 0, 0, 0.05);
  --Switch-round: rgba(0, 0, 0, 0.12);
  --linear-gradient-white: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 1) 7%,
    #fff 30%,
    #fff 100%
  );
  --collection-linear: linear-gradient(270deg, #202632 20%, #303540 80%);
  --linear-gradient-dark: linear-gradient(180deg, #202632 60%, rgba(32, 38, 50, 0) 100%);
  --linear-gradient-dark-bottom: linear-gradient(360deg, #202632 87%, rgba(32, 38, 50, 0) 100%);
  --linear-gradient-delete: linear-gradient(270deg, #f9fafa -2.3%, #f9fafa 47%, rgba(249, 250, 250, 0) 100%);
  --linear-gradient-more: linear-gradient(270deg, #f9fafa -2.3%, #f9fafa 47%, rgba(249, 250, 250, 0) 100%);
  --linear-gradient-hover: linear-gradient(
    270deg,
    #f2f2f2 0%,
    rgba(242, 242, 242, 0.9) 47.92%,
    rgba(242, 242, 242, 0) 100%
  );
  --black-bg: rgba(0, 0, 0, 0.35);
  --linear-gradient-gray: linear-gradient(0deg, #f7f7f7, #f7f7f7), #fff1e7;
}

html,
body {
  position: fixed;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: var(--primary-font-stack);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

pre {
  white-space: pre-wrap;
}

#root {
  overflow: hidden;
  height: 100%;
}
.boxlist {
  padding: 0px 8px;
}
.sidebarWrapper .arrow-navbar {
  padding: 2px 3px;
}
.sidebarWrapper .sidebarBlock .electron-app {
  margin: 0px 0 10px 0;
}
.sidebarWrapper .sidebarBlock .imgWrapper {
  padding: 0 0px 20px 0px;
}
.sidebarWrapper .arrow-navbar i {
  font-size: 16px;
}
.sidebarWrapper .resize-arrow-navbar {
  max-width: 50px;
  justify-content: center;
}
.sidebar + .resizeWrapper {
  margin-left: -12px;
}
.sidebar + .resizeWrapper .resize {
  left: 106px;
}
blockquote,
pre,
s {
  margin: 0;
  padding: 0;
}
.status-menu .MuiMenu-paper {
  margin-left: 55px;
}

@media only screen and (max-width: 640px) {
  .status-menu .MuiMenu-paper {
    margin-left: 0;
  }
}

.iconsvg {
  height: 20px;
}

.inDevelopment {
  display: none !important;
}

.ql-toolbar.ql-snow .ql-formats button svg {
  display: none;
}
.ql-picker-label svg {
  display: none;
}

.ql-editor.ql-blank::before {
  color: #b4b5ba;
  color: var(--disable-color);
}

.ql-expanded .ql-picker-label::after {
  font-family: 'salescamp';
  content: '\e970';
  font-size: 20px;
  display: inline-block;
  position: absolute;
  top: 1px;
  right: 6px;
}
.ql-picker-label::after {
  font-family: 'salescamp';
  content: '\e925';
  font-size: 20px;
  display: inline-block;
  position: absolute;
  top: 1px;
  right: 6px;
}
.iconsvg {
  height: 20px;
}
.ql-bold::after {
  font-family: 'salescamp';
  content: '\e90d';
  font-size: 16px;
}
.ql-italic::after {
  font-family: 'salescamp';
  content: '\e93d';
  font-size: 16px;
}
.ql-underline::after {
  font-family: 'salescamp';
  content: '\e96f';
  font-size: 16px;
}
.ql-strike::after {
  font-family: 'salescamp';
  content: '\e962';
  font-size: 16px;
}
.ql-blockquote::after {
  font-family: 'salescamp';
  content: '\e955';
  font-size: 16px;
}
.ql-code-block::after {
  font-family: 'salescamp';
  content: '\e918';
  font-size: 16px;
}
.ql-link::after {
  font-family: 'salescamp';
  content: '\e942';
  font-size: 16px;
}
.ql-clean::after {
  font-family: 'salescamp';
  content: '\e92c';
  font-size: 16px;
}
.ql-list[value*='ordered']::after {
  font-family: 'salescamp';
  content: '\e94f';
  font-size: 16px;
}
.ql-list[value*='bullet']::after {
  font-family: 'salescamp';
  content: '\e90f';
  font-size: 16px;
}
.quill_format .ql-size-huge {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
  color: var(--dark-color);
}
@media only screen and (max-width: 640px) {
  .quill_format .ql-size-huge {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: #333333;
    color: var(--dark-color);
  }
}
.quill_format .ql-size-large {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
  color: var(--dark-color);
}
@media only screen and (max-width: 640px) {
  .quill_format .ql-size-large {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    color: var(--dark-color);
  }
}
.quill_format .ql-size-small {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #333333;
  color: var(--dark-color);
}
.quill_format .ql-syntax {
  font-weight: normal;
  font-size: 12px;
  color: #333333;
  color: var(--dark-color);
  padding: 6px 8px;
  background: #f7f7f7;
  background: var(--cultured-color);
  border: 1px solid #e4e4e5;
  border: 1px solid var(--gray-border-color);
  border-radius: 4px;
  margin: 10px 0;
}
.quill_format p {
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  color: #333333;
  color: var(--dark-color);
}
.quill_format strong {
  color: #333333;
  color: var(--dark-color);
  font-weight: bold !important;
}
.quill_format em {
  color: #333333;
  color: var(--dark-color);
}
.quill_format u {
  color: #333333;
  color: var(--dark-color);
}
.quill_format s {
  color: #333333;
  color: var(--dark-color);
}
.quill_format ul {
  margin: 10px 0;
}
.quill_format ol {
  margin: 10px 0;
}
.quill_format li {
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  color: #333333;
  color: var(--dark-color);
  margin-bottom: 6px;
}
.quill_format li:last-child {
  margin-bottom: 0;
}
.quill_format a {
  text-decoration: none;
}
.quill_format blockquote {
  font-size: 14px;
  font-weight: 500;
  padding: 4px 0px 4px 10px;
  border-left: 3px solid #2e69ce;
  border-left: 3px solid var(--primary-color);
  margin: 10px 0 10px 12px;
  color: #333333;
  color: var(--dark-color);
}

